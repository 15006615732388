<template>
  <div v-if="show">
    <!-- <span class="day">{{ day }}</span>
    <i>:</i> -->
    <span class="hr">{{ hr }}</span>
    <i>:</i>
    <span class="min">{{ min }}</span>
    <i>:</i>
    <span class="sec">{{ sec }}</span>
  </div>
</template>

<script>
export default {
  props: {
    times: {
      default: false,
    },
  },
  data() {
    return {
      day: 0,
      hr: 0,
      min: 0,
      sec: 0,
      timer: null,
      show: true,
    };
  },
  mounted: function() {
    if (this.times) {
      this.countdown();
    }
  },
  methods: {
    countdown: function() {
      clearTimeout(this.timer);
      const end = this.times * 1000;
      const now = Date.parse(new Date());
      const msec = end - now;
      if (!this.times || msec <= 0) {
        this.show = false;
        return false;
      }

      let day = parseInt(msec / 1000 / 60 / 60 / 24);
      let hr = parseInt((msec / 1000 / 60 / 60) % 24);
      let min = parseInt((msec / 1000 / 60) % 60);
      let sec = parseInt((msec / 1000) % 60);

      this.day = day;
      let _hr = day * 24 + hr;
      this.hr = _hr > 9 ? _hr : "0" + _hr;
      this.min = min > 9 ? min : "0" + min;
      this.sec = sec > 9 ? sec : "0" + sec;
      const that = this;
      this.timer = setTimeout(function() {
        if (msec && msec <= 0) {
          clearTimeout(this.timer);
          this.show = false;
        } else {
          that.countdown();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
