<template>
  <div>
    <div id="buy" v-if="errorText.length == 0">
      <oAddress :addressArr.sync="form.address_info"></oAddress>
      <oProduct :goods="form.goods_list"></oProduct>
      <oDataInfo
        :mailPrice="form.freight"
        :totalPrice="form.total_price"
        :goodsPrice="form.goods_total_price"
        :active="form.mansong_info"
        :len="len"
        :remarks.sync="remarks"
      ></oDataInfo>
      <oVouchers
        :vouchers="form.vouchers"
        :voucher.sync="voucher"
        :totalPrice="form.total_price"
        :balance="form.member_info && form.member_info.available_predeposit"
        :balancePayState.sync="balancePayState"
      ></oVouchers>
      <oSubmit
        @errorEmit="errorEmit"
        :voucher="voucher"
        :len="len"
        :form="form"
        :cartForm="cartForm"
        :balancePayState="balancePayState"
        :remarks="remarks"
      ></oSubmit>
    </div>

    <!-- 报错 -->
    <van-empty :description="errorText" v-else image="error">
      <van-button round class="bottom-button " @click="$router.go(0)"
        >返回</van-button
      >
      <van-button
        round
        type="primary"
        class="bottom-button"
        @click="$router.push({ name: 'HomeIndex' })"
        >回到首页</van-button
      >
    </van-empty>
  </div>
</template>

<script>
import { post } from "@get/http";
import { Empty, Button } from "vant";
import oAddress from "./components/address/index.vue";
import oProduct from "./components/product/index.vue";
import oDataInfo from "./components/dataInfo/index.vue";
import oVouchers from "./components/vouchers/index.vue";
import oSubmit from "./components/submit/index.vue";
export default {
  components: {
    oAddress,
    oDataInfo,
    oProduct,
    oVouchers,
    oSubmit,
    vanEmpty: Empty,
    vanButton: Button,
  },
  data() {
    return {
      remarks: "", //用户备注
      form: {}, //请求的数据
      cartForm: [], //购物车数据
      voucher: {}, //代金券
      len: 0, //产品数量
      balancePayState: false, //是否零钱支付
      errorText: "",
    };
  },
  methods: {
    errorEmit(data) {
      this.errorText = data;
    },
  },
  async created() {
    let r = this.$route.query;
    r.type ??= 0;
    let cartArr = r.cart_id.split(",");
    let form = [];
    let len = 0;
    if (cartArr) {
      for (let index = 0; index < cartArr.length; index++) {
        let s = cartArr[index].split("|");
        if (s.length > 1) {
          form[index] = {
            goods_id: s[0],
            num: s[1],
          };
          len += Number(s[1]);
        }
      }
      if (r.type == 0) {
        this.cartForm = { choose: form, ms_id: r.ms_id };
      }

      if (r.type == 1) {
        this.cartForm = { ...form[0] };
      }

      if (len) {
        this.len = len;
      }
    }
    let result = await post("/v3.memberMultiSelectGoods/buyStep1", {
      data: {
        body: this.cartForm,
        type: r.type,
      },
    });
    this.form = result;
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#buy {
  padding-bottom: rem(190);
}
.bottom-button {
  display: block;
  margin: 0 auto;
  margin-bottom: rem(30);
  width: rem(300);
}
</style>
