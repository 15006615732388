<template>
  <ul id="gift">
    <li
      class="gift flex-container"
      v-for="(g, index) in combination"
      :key="g.gift_id"
    >
      <img
        :src="
          `http://image.shop.19jiang.cn/home/store/goods/2/${g.goods_image}`
        "
      />
      <h2>
        <i>清单{{ index + 1 }}</i>
        {{ g.goods_name }}
      </h2>
      <span class="flex1">
        <p>{{ g.goods_price }}</p>
        x{{ len }}</span
      >
    </li>
  </ul>
</template>

<script>
import oTime from "../time";
export default {
  components: {
    oTime,
  },
  props: {
    combination: {},
    len: {
      default: 1,
    },
  },
  data() {
    return {
      //  +
      nowTime: new Date().getTime() + 24 * 3 * 60 * 60 * 1000,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#gift {
  margin-top: rem(20);
}
.gift_num_tip {
  span {
    color: #f21a4b;
    font-size: rem(20);
  }
}
.gift {
  padding: rem(10) rem(30);
  i {
    display: inline-block;
    width: rem(56);
    font-size: rem(18);
    // height: rem(20);
    // line-height: rem(20);
    text-align: center;
    color: #f21a4b;
    border: 1px solid #f21a4b;
  }
  img {
    width: rem(80);
    height: rem(80);
  }
  h2 {
    margin-left: rem(24);
    width: rem(450);
    font-size: rem(22);
  }
  span {
    display: block;
    text-align: right;
    font-size: rem(20);
  }
}
</style>
