<template>
  <div class="active_wrap" v-if="g.ifgroupbuy || g.ifxianshi">
    <!-- 如果抢购和限时折扣存在 -->
    <span class="active_tip">
      <oTime
        :times="g.xianshi_end_time || g.groupbuy_endtime"
        style="display:inline-block;font-size:16px;color: #de3e27;"
      ></oTime>
      {{ g.qianggou_desc ? "抢购" : "折扣" }}
      即将结束，限时{{ g.qianggou_desc ? "抢购" : "优惠" }}价<i>{{
        g.goods_price
      }}</i
      >，<b>原价{{ g.original_price }}</b>
    </span>
  </div>
</template>

<script>
import oTime from "../time";
export default {
  components: {
    oTime,
  },
  props: {
    g: {},
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.active_wrap {
  color: #989898;
  margin-bottom: rem(14);
  b {
    color: #989898;
    text-decoration: line-through;
  }
  i {
    // color: #de3e27;
  }
}
</style>
