<template>
  <div>
    <div id="submit" class="submit_shadow flex-container">
      <div class="info flex1">
        <p class="data">
          <i>共 {{ len }} 件, </i> &nbsp;合计:<span>
            <template v-if="voucher && voucher.voucher_price">
              <b>￥</b
              >{{
                changePrice(
                  form.total_price - parseFloat(voucher.voucher_price)
                )
              }}
            </template>
            <template v-else> <b>￥</b>{{ form.total_price }} </template>
          </span>
        </p>
        <p class="tips" v-if="voucher && voucher.voucher_price">
          已使用优惠券，节省{{ voucher.voucher_price }}元
        </p>
      </div>
      <div class="submit_form" @click="selectPay">
        支付订单
      </div>
    </div>
    <vanPopup
      closeable
      round
      v-model="showPassword"
      position="bottom"
      :style="{ height: '70%' }"
    >
      <h1 class="balance_pay_title">请输入零钱支付密码</h1>
      <!-- 密码输入框 -->
      <van-password-input
        :value="keyValue"
        :focused="showKeyboard"
        info="密码为 6 位数字"
        @focus="showKeyboard = true"
      />
      <!-- 数字键盘 -->
      <van-number-keyboard
        close-button-text="完成"
        :value="keyValue"
        :show="showKeyboard"
        @input="keyInput"
        @close="pay"
        @delete="delInput"
        theme="custom"
        :extra-key="['忘记']"
      />
    </vanPopup>
  </div>
</template>

<script>
import { PasswordInput, NumberKeyboard, Popup, Dialog, Toast } from "vant";
import { pay } from "@get/pay";
import { mapState } from "vuex";
import { post } from "@get/http";

export default {
  components: {
    vanPasswordInput: PasswordInput,
    vanNumberKeyboard: NumberKeyboard,
    vanPopup: Popup,
    vanDialog: Dialog,
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
    }),
  },
  data() {
    return {
      keyValue: "", //密码
      showKeyboard: true, //展开键盘
      showPassword: false, // 打开密码输入
      payment_list: {}, //可以支付的列表
      submitFrom: {
        uniapp: 0, // 是否是小程序
        pay_type: "pay_new", // 订单支付显示的数据
        payment_info: {
          password: "", // 支付密码
          rcb_pay: 0, // 是否使用充值卡进行支付
          pd_pay: 0, // 是否使用预存款进行支付
          payment_code: "alipay_h5", // 支付方式
        },
      },
    };
  },
  props: {
    remarks: {
      default: "",
      tyoe: String,
    }, //用户备注
    balancePayState: {
      default: false,
      type: Boolean,
    }, // 是否使用零钱支付
    voucher: {
      type: Object,
      default: function() {
        return {};
      },
    }, //当前选择的优惠券
    len: {
      default: 0,
      type: Number,
    }, //件数
    form: {}, //  请求的form数据
    cartForm: {}, //  购物车产品
  },
  methods: {
    changePrice(price) {
      return price.toFixed(2);
    },
    async selectPay() {
      // for (let index = 0; index < this.form?.goods_list.length; index++) {
      //   if (this.form?.goods_list[index]?.goods_msg) {
      //     Toast(this.form?.goods_list[index]?.goods_name + "不能购买哦~");
      //     return false;
      //   }
      // }

      if (this.form?.total_num == 0) {
        Toast("有商品限购了哦~");
        return false;
      }

      if (this.balancePayState) {
        this.showPassword = true;
      } else {
        if (this.payment_list && !this.payment_list.payment_code) {
          await this.getPayment();
        }
        this.pay();
      }
    },
    // okInput() {
    //   this.pay();
    // },
    async pay() {
      if (!this.form.address_info.address_id) {
        Toast("请选择您的收货地址");
        return false;
      }
      Toast.loading({
        message: "调用支付中",
        forbidClick: true,
        loadingType: "spinner",
      });
      try {
        let r = this.$route.query;
        if (this.balancePayState && this.keyValue.length != 6) {
          Toast("请输入正确的6位密码");
          return false;
        }

        let subForm = {
          // ifcart: Number(r.buy_now) ? 0 : 1, //是否为购物车购买的
          body: this.cartForm,
          address_id: this.form.address_info.address_id,
          voucher_id: this.voucher.voucher_id,
          rcb_pay: 0, //充值卡
          pd_pay: this.balancePayState ? 1 : 0, //零钱支付
          password: this.keyValue, //零钱支付密码
          pay_message: this.remarks, //备注,
          type: (r.type ??= 0),
          payment_code: this.balancePayState
            ? "online"
            : this.payment_list.payment_code,
        };

        let step2 = await post(
          "/v3.memberMultiSelectGoods/buyStep2",
          { data: { ...subForm } },
          { result: true, resultKey: "code" }
        );
        if (step2.code === 12001 && step2.result.payment_code === "online") {
          this.$router.push({
            name: "BuySuccess",
            query: {
              pay_sn: step2.result.pay_sn,
            },
          });
        } else if (
          step2.code === 10001 ||
          step2.code === 10006 ||
          step2.code === 10099
        ) {
          this.$emit("errorEmit", step2.message);
        } else {
          this.submitFrom.payment_info.payment_code = this.payment_list.payment_code;

          if (this.payment_list.payment_code == "wxpay_minipro") {
            wx.miniProgram.redirectTo({
              url:
                "../pay/pay?action=" +
                this.submitFrom.pay_type +
                "&key=" +
                this.token +
                "&pay_sn=" +
                step2.result.pay_sn +
                "&password=" +
                this.submitFrom.payment_info.password +
                "&rcb_pay=" +
                this.submitFrom.payment_info.rcb_pay +
                "&pd_pay=" +
                this.submitFrom.payment_info.pd_pay +
                "&payment_code=" +
                this.submitFrom.payment_info.payment_code,
            });
            return;
          }

          pay(
            step2.result.pay_sn,
            this.submitFrom.pay_type,
            this.submitFrom.payment_info,
            this.token,
            this.submitFrom.uniapp
          )
            .then((result) => {
              Toast("正在准备支付");
              document.write(result);
            })
            .catch(function(error) {
              if (error.message) {
                Toast(error.message);
              } else {
                console.error(error);
                // document.write(error);
              }
            });
        }
      } catch (error) {
        console.error(error);
        Toast(error);
      }
    },
    async getPayment() {
      let result = await post("/Memberpayment/payment_list");
      if (result.payment_list && result.payment_list[0]) {
        this.payment_list = result.payment_list[0];
      }
    },
    keyInput(val) {
      if (val == "忘记") {
        Dialog.confirm({
          title: "忘记密码",
          message: "需要帮忙找回密码吗？",
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.keyValue += val;
      }
    },
    delInput() {
      // let a =
      if (this.keyValue.length != 0) {
        this.keyValue = this.keyValue.slice(0, this.keyValue.length - 1);
      }
    },
  },
  watch: {
    showPassword() {
      if (this.showPassword == false) {
        this.keyValue = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#submit {
  z-index: 99;
  padding: 0 rem(24);
  box-sizing: border-box;
  align-items: center;
  height: rem(110);
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;

  box-shadow: 0 rem(-3) rem(10) rgba(204, 204, 204, 0.2);
}
.data,
.tips {
  text-align: right;
  font-size: rem(28);
}
.data {
  > i {
    color: #a7a7a9;
  }
  > span {
    color: #f75e1b;
    font-size: rem(34);
    b {
      font-size: rem(22);
    }
  }
}
.info {
  margin-right: rem(24);
}
.submit_form {
  width: rem(200);
  height: rem(80);
  background: linear-gradient(to right, #ff9633, #f75e1b);
  border-radius: rem(40);
  font-size: rem(30);
  text-align: center;
  color: #fff;
  line-height: rem(80);
}
.balance_pay_title {
  font-size: rem(36);
  color: #a7a7a9;
  padding: rem(30);
  text-align: center;
}
</style>
