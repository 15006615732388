<template>
  <div class="shop_product">
    <h2 class="shop_title">
      <img
        src="http://image.shop.19jiang.cn/home/mofang/alioss_6107944e3a051.jpg"
      />
      十九匠官方店
    </h2>
    <ul class="shop-product_ul">
      <li v-for="g in goods" :key="g.goods_id">
        <oActiveTip :g="g"></oActiveTip>
        <div class="flex-container">
          <div class="headimg">
            <img
              :src="
                `http://image.shop.19jiang.cn/home/store/goods/2/${g.goods_image}`
              "
            />
          </div>
          <div class="info ">
            <h3>
              {{
                g.bl_desc
                  ? g.bl_desc
                  : getChangeName(g.goods_name, g.goods_spec.spvalue_name)
              }}
            </h3>
            <span v-if="!g.bl_desc">规格：{{ g.goods_spec.spvalue_name }}</span>

            <ul class="tip flex-container">
              <li v-if="g.ifgroupbuy">
                {{ g.promotions_name }}
              </li>
              <li v-if="g.ifxianshi">
                {{ g.promotions_name }}
              </li>
              <li v-if="g.ifmgdiscount">
                会员价
              </li>
              <li v-if="g.bl_desc">优惠套餐</li>
              <li v-if="g.welfare_desc">{{ g.welfare_desc }}</li>
              <li v-if="g.goods_msg">{{ g.goods_msg }}</li>
            </ul>
          </div>
          <div class="data flex1">
            <b><i>￥</i>{{ g.goods_price }}</b>
            <p>x{{ g.buy_num }}</p>
          </div>
        </div>

        <oCombination
          :combination="g.bl_goods_list"
          v-if="g.bl_goods_list"
          :len="g.buy_num"
        ></oCombination>
        <oGift :gift="g.gift_list" v-if="g.gift_list"></oGift>
      </li>
    </ul>
  </div>
</template>

<script>
import oGift from "./components/gift";
import oCombination from "./components/combination";
import oActiveTip from "./components/activeTip";
// import { countdown } from "../time.js";

export default {
  components: {
    oGift,
    oCombination,
    oActiveTip,
  },
  props: {
    goods: {},
  },
  data() {
    return {};
  },

  created() {},
  methods: {
    getChangeName(name, spec) {
      return name.split(` ${spec}`)[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.shop_product {
  background: #fff;
  border-radius: rem(20);
  width: rem(710);
  margin: 0 auto;
  box-sizing: border-box;
  padding: rem(30) rem(24);
}
.shop_title {
  height: rem(50);
  line-height: rem(50);
  img {
    width: rem(50);
  }
  font-size: rem(32);
  color: #484848;
}

.shop-product_ul {
  > li {
    &:first-of-type {
      margin-top: rem(40);
    }
    margin-bottom: rem(40);
    box-sizing: border-box;

    .headimg {
      display: block;
      width: rem(180);
      height: rem(180);

      margin-right: rem(26);
      // box-sizing: border-box;
      img {
        width: 100%;
        border-radius: rem(10);
        height: 100%;
      }
    }
    .info {
      // margin-right: rem(40);
      width: rem(300);
      > h3 {
        color: #2e2e2e;
        font-size: rem(28);
        @include ell2;
        margin-bottom: rem(16);
      }
      > span {
        // display: block;
        background: #fafafa;
        border-radius: rem(20);
        color: #a7a7a9;
        padding: rem(8);
        font-size: rem(26);
        display: inline-block;
        margin-bottom: rem(16);
      }
      .tip {
        li {
          margin-left: rem(8);
          background: #f6e8db;
          color: #f75e1b;
          padding: 0 rem(4);
          font-size: rem(20);
          border-radius: rem(6);
        }
      }
    }
    .data {
      // width: rem(120);
      text-align: right;
      b {
        color: #2f2f2f;
        font-size: rem(26);
        i {
          font-size: rem(20);
        }
      }
      p {
        font-size: rem(24);
        color: #989898;
      }
    }
  }
}
</style>
