<template>
  <div id="dataInfo">
    <ul class="d-ul">
      <li class="flex-container d-li">
        <span class="title">商品总金额</span>
        <i class="price flex1"><i>￥</i>{{ goodsPrice }} </i>
      </li>
      <li class="flex-container d-li" v-if="active && active.amount">
        <span class="title">{{ active.mansong_name }}</span>
        <b class="tips flex1"
          >满{{ active.mansongrule_price }}-{{ active.amount }}</b
        >
        <i class="price flex1"><i>-￥</i>{{ active.amount }} </i>
      </li>
      <li class="flex-container d-li">
        <span class="title">运费</span>
        <b class="tips flex1">普通配送</b>
        <i class="result">{{ mailPrice ? `${mailPrice}元` : "快递 免邮" }}</i>
        <img src="@image/goods/review_more.png" />
      </li>

      <li class="flex-container d-li">
        <span class="title">订单备注</span>
        <input
          type="text"
          @input="inpuRemarks"
          v-model="defaultRemarks"
          placeholder="选填,请先和商家协商一致"
          class="flex1"
        />
      </li>
      <li v-if="active && active.gift" class="flex-container d-li">
        <span class="title">满赠</span>
        <h2 class="gift_h2 flex1">
          <!-- <i>清单{{ index + 1 }}</i> -->
          {{ active.gift.goods_name }}
        </h2>
      </li>

      <li class="d-li totalprice">
        <span>共{{ len }}件</span> 小计:<i>￥</i><b>{{ totalPrice }}</b>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    mailPrice: {}, //邮费
    goodsPrice: {}, //产品总价格
    totalPrice: {}, // 总价/小计
    active: {}, //全店满送活动
    len: {}, //产品活动
    remarks: {}, //备注
  },
  data() {
    return {
      defaultRemarks: "",
    };
  },
  methods: {
    inpuRemarks(val) {
      this.$emit("update:remarks", this.defaultRemarks);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#dataInfo {
  width: rem(710);
  margin: 0 auto rem(20);
  border-radius: rem(20);
  background: #fff;
  padding: 0 rem(24);
  box-sizing: border-box;
  .d-ul {
  }
  .d-li {
    input {
      border: 0;
    }
    & ~ .totalprice {
      > span {
        color: #9e9e9e;
      }
      > i {
        color: #f65a21;
        font-size: rem(18);
      }
      > b {
        color: #f65a21;
        font-size: rem(32);
      }
      text-align: right;
    }

    padding: rem(20) 0;
    font-size: rem(26);
    align-items: center;
    .title {
      width: rem(188);
      text-align: right;
      margin-right: rem(24);
      color: #202020;
    }
    .tips {
      color: #939393;
    }
    .result {
      color: #202020;
      margin-right: rem(24);
    }
    .price {
      color: #f65a21;
      text-align: right;
      font-size: rem(32);
      i {
        font-size: rem(18);
      }
    }

    img {
      width: rem(23);
      height: rem(23);
      margin-right: rem(10);
    }
  }
}

.gift_h2 {
  @include ell2;
  text-align: right;
}
</style>
