<template>
  <!-- gray -->
  <div class="meCoupon_ul">
    <h2>选择优惠券</h2>
    <div class="meCoupon_li" v-for="(coupon, index) in vouchers" :key="index">
      <div class="important flex-container">
        <div class="coupon_price flex-container">
          <p class="price">
            <i>￥</i>
            <b>{{ coupon.voucher_price }}</b>
          </p>
          <p class="tips">优惠券</p>

          <img src="@image/me/coupon_jtop2.png" class="top" />
          <img
            src="@image/me/coupon_jbottom2.png"
            class="bottom"
            v-if="!infoShow"
          />
        </div>
        <div class="coupon_data flex-container">
          <p class="title" style="margin-bottom:0.6rem">
            {{ coupon.voucher_title }}
          </p>
          <b class="time">{{ coupon.voucher_startdate | time }}~</b>
          <b class="time">{{ coupon.voucher_enddate | time }}期间</b>
          <!-- <b class="time lastTime">还剩1天到期</b> -->
          <!-- <span
          class="rules"
          @click="infoShow = !infoShow"
          :class="infoShow ? 'rulesShow' : 'rulesHide'"
          >使用规则</span
        > -->
        </div>
        <template>
          <div class="checkCoupon flex1">
            <van-checkbox
              v-model="checked[index]"
              @click="changeCheck(index)"
              checked-color="#ee0a24"
            ></van-checkbox>
          </div>
        </template>
      </div>

      <div class="info" v-if="infoShow">
        <p>{{ coupon.voucher_desc ? coupon.voucher_desc : "无" }}</p>
        <p class="only_storeName">
          <!-- 仅限于{{ coupon.vouchertemplate_storename }}店铺使用 -->
        </p>
      </div>
    </div>

    <div class="button">
      <div class="no" @click="noUseCoupon">
        不使用
      </div>
      <div class="yes" @click="thisUseCoupon">
        完成
      </div>
    </div>
  </div>
</template>
<script>
import { Checkbox } from "vant";
export default {
  name: "",
  components: {
    vanCheckbox: Checkbox,
  },
  props: {
    vouchers: {},

    boo: {
      default: false,
    },
  },
  created() {
    if (this.vouchers && this.vouchers.length != 0) {
      for (let index = 0; index < this.vouchers.length; index++) {
        if (index == 0) {
          this.checked[index] = true;
        } else {
          this.checked[index] = false;
        }
      }
    }
    //do something after creating vue instance
  },

  methods: {
    noUseCoupon() {
      for (let index = 0; index < this.checked.length; index++) {
        this.$set(this.checked, index, false);
      }
      this.$emit("enter", {});
      this.$emit("update:boo", false);
    },
    thisUseCoupon() {
      for (let index = 0; index < this.checked.length; index++) {
        if (this.checked[index] == true) {
          this.$emit("enter", this.vouchers[index]);
          this.$emit("update:boo", false);
          break;
        }
      }
    },
    changeCheck(i) {
      for (let index = 0; index < this.checked.length; index++) {
        this.$set(this.checked, index, false);
      }

      // this.checked[i] = true;
      this.$set(this.checked, i, true);
      // console.log(i, this.checked);
    },
  },
  data: () => ({
    checked: [],
    infoShow: false,
  }),
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
h2 {
  font-size: rem(40);
  color: #b2b2b2;
  text-align: center;
  padding: rem(30);
}
.only_storeName {
  color: #ff4747;
  margin-top: rem(10);
}
.meCoupon_ul {
  padding-bottom: rem(120);
}
.meCoupon_li {
  width: rem(710);
  margin: 0 auto rem(20);
  background: #fff;
  border-radius: rem(10);

  .info {
    // align-items: center;

    // min-height: rem(68);
    font-size: rem(20);
    color: #808080;
    // line-height: rem(68);
    padding: rem(20);
  }
  .important {
    .coupon_price {
      .top,
      .bottom {
        position: absolute;
        width: rem(16);
        height: rem(8);
        right: rem(-8);
      }
      .top {
        top: 0;
      }
      .bottom {
        bottom: 0;
      }

      width: rem(220);
      height: rem(200);
      background: url("~@image/me/coupon_quan.png") no-repeat;
      background-size: rem(220) rem(200);
      justify-content: center;
      flex-direction: column;
      position: relative;
      .price {
        text-align: center;
        color: #ff4747;

        i {
          font-size: rem(30);
          font-weight: bold;
        }
        b {
          font-size: rem(64);
          font-weight: bold;
        }
      }
      .tips {
        text-align: center;
        font-size: rem(22);
        font-weight: bold;
        color: #ff4747;
      }
    }

    .coupon_data {
      width: rem(320);
      justify-content: center;
      flex-direction: column;
      padding-left: rem(24);
      box-sizing: border-box;
      .title {
        font-size: rem(26);
        font-weight: bold;
        color: #333333;
        @include ell2;
      }
      .time {
        font-size: rem(22);
        font-weight: bold;
        color: #b2b2b2;
        display: block;
      }
      .lastTime {
        color: #ff4747;
      }
      .rules {
        margin-top: rem(16);
        display: block;
        width: rem(106);
        font-size: rem(20);
        color: #b2b2b2;
      }
      .rulesShow {
        background: url("~@image/me/coupon_show.png") no-repeat center right;
        background-size: rem(20) rem(20);
      }
      .rulesHide {
        background: url("~@image/me/coupon_hide.png") no-repeat center right;
        background-size: rem(20) rem(20);
      }
    }
  }

  .go_btn {
    width: rem(120);
    height: rem(52);
    background: #ff4747;
    box-shadow: 0px rem(4) rem(8) 0px rgba(255, 71, 71, 0.2);
    border-radius: rem(26);
    font-size: rem(26);
    font-weight: bold;
    text-align: center;
    line-height: rem(52);
    color: #ffffff;
    margin: rem(74) auto;
  }
}

.state {
  margin: rem(40) auto 0;
  img {
    width: rem(120);
    height: rem(120);
    display: block;
  }
}

.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);

  filter: grayscale(100%);

  filter: gray;
}

.checkCoupon {
  display: flex;
  justify-content: flex-end;
  margin-right: rem(20);
  // justify-self: flex-end;
}

.button {
  div {
    width: 50%;
    display: inline-block;
    background: linear-gradient(to right, #ffd01e, #ff8917);
  }
  .no {
    // background: linear-gradient(to right, #dfdedb, #858484);
    opacity: 0.3;
    border-radius: rem(40) 0 0 rem(40);
  }
  .yes {
    border-radius: 0 rem(40) rem(40) 0;
  }

  position: fixed;
  bottom: rem(40);
  left: 0;
  right: 0;

  margin: 0 auto;

  width: rem(710);
  height: rem(80);
  line-height: rem(80);
  text-align: center;
  font-size: rem(34);
  z-index: 99;
  color: #fff;
}
</style>
